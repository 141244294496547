export const ALOJAMIENTO_ROUTE = '/alojamiento';
export const CURSO_FELE_INTENSIVO_ROUTE = '/cursos-para-profesores-de-espanol/curso-fele';
export const REQUSITO_DE_ACCESO_ROUTE =
  '/cursos-para-profesores-de-espanol/curso-fele/requisitos-de-acceso';
export const CURSO_ELE_PLUS_ROUTE = '/cursos-para-profesores-de-espanol/ele-mas/';
export const DISCOUNT_SPANISH_LESSONS_ROUTE = '/discount-spanish-lessons';
export const CURSO_FECHAS_ROUTE = '/cursos-para-profesores-de-espanol/fechas';
export const ORIENTACION_PROFESIONAL_ROUTE =
  '/trabajar-como-profesor-de-espanol/orientacion-profesional';
export const ACREDITACIONES_Y_ASOCIACIONES_ROUTE = '/sobre-nosotros/acreditaciones-y-asociaciones';
export const CONOCE_EL_EQUIPO_ROUTE = '/conoce-al-equipo';
export const GRADUADOS_DE_ELE_BARCELONA_ROUTE =
  '/cursos-para-profesores-de-espanol/graduados-de-ele-barcelona';
export const PREGUNTAS_FRECUENTES_ROUTE = '/cursos-para-profesores-de-espanol/preguntas-frecuentes';
export const BLOG_ROUTE = '/blog';
export const MEDIATECA_RECURSOS_ROUTE = '/mediateca-recursos-e-ideas';
export const CONTACTAR_ROUTE = '/contactar';
export const INSCRIPCION_ROUTE = '/inscripcion';
export const DESCUENTOS_ROUTE = '/cursos-para-profesores-de-espanol/fechas/descuentos';
export const TRABAJAR_COMO_PROFESOR_ROUTE = '/trabajar-como-profesor-de-espanol';
export const SOBRE_NOSTROS_ROUTE = '/sobre-nosotros';
export const DESCARDA_EL_FOLLETO_ROUTE = '/descargar-folleto';
export const QUESIGNIFICAELE = '/que-significa-ele';
export const POLITICA_DE_PRIVACIDAD_ROUTE = '/politica-de-privacidad';
export const EN_SIGN_UP = "/en-sign-up";