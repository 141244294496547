import React from 'react';
import { Link } from 'gatsby';

import {
  ALOJAMIENTO_ROUTE,
  CURSO_FELE_INTENSIVO_ROUTE,
  CURSO_ELE_PLUS_ROUTE,
  DISCOUNT_SPANISH_LESSONS_ROUTE,
  CURSO_FECHAS_ROUTE,
  // ORIENTACION_PROFESIONAL_ROUTE,
  ACREDITACIONES_Y_ASOCIACIONES_ROUTE,
  CONOCE_EL_EQUIPO_ROUTE,
  GRADUADOS_DE_ELE_BARCELONA_ROUTE,
  PREGUNTAS_FRECUENTES_ROUTE,
  BLOG_ROUTE,
  MEDIATECA_RECURSOS_ROUTE,
  CONTACTAR_ROUTE,
  // INSCRIPCION_ROUTE,
  DESCUENTOS_ROUTE,
  // TRABAJAR_COMO_PROFESOR_ROUTE,
  REQUSITO_DE_ACCESO_ROUTE,
} from '../constants/routes';

import imgFooterLogo from '../assets/images/footer/ELE-Barcelona-logo.svg';
import imgAdress from '../assets/images/footer/address.svg';
import imgPhone from '../assets/images/footer/phone-call.svg';
import imgMail from '../assets/images/footer/email-3.svg';
import ImgFacebook from '../assets/images/footer/facebook.inline.svg';
import ImgTwitter from '../assets/images/footer/twitter.inline.svg';
import ImgInstagram from '../assets/images/footer/Instagram.inline.svg';
import ImgYouTube from '../assets/images/footer/youtube.inline.svg';
import imgWha from '../assets/images/pages/contacto/whatsapp-green.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
            <h3 className="footer__subtitle">Compañía</h3>
            <Link to="/la-historia-de-ele-barcelona/" className="c-text-14 footer__link">
              La historia de ELE Barcelona
            </Link>
            <Link to="/que-significa-ele/" className="c-text-14 footer__link">
              ¿Qué significa ELE?
            </Link>
            <Link to="/covid-19/" className="c-text-14 footer__link">
              Covid 19
            </Link>
            <Link to={CONOCE_EL_EQUIPO_ROUTE} className="c-text-14 footer__link">
              Conoce al equipo
            </Link>
            <Link to={BLOG_ROUTE} className="c-text-14 footer__link">
              Blog
            </Link>
            <Link to={ACREDITACIONES_Y_ASOCIACIONES_ROUTE} className="c-text-14 footer__link">
              Acreditaciones y asociaciones
            </Link>
            <Link to={GRADUADOS_DE_ELE_BARCELONA_ROUTE} className="c-text-14 footer__link">
              Graduados de ELE Barcelona
            </Link>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
            <h3 className="footer__subtitle">Cursos ELE</h3>
            <Link to={CURSO_FELE_INTENSIVO_ROUTE} className="c-text-14 footer__link">
              Curso FELE Intensivo
            </Link>
            <Link to={CURSO_ELE_PLUS_ROUTE} className="c-text-14 footer__link">
              Curso de 1 semana ELE+
            </Link>
            <Link to={DISCOUNT_SPANISH_LESSONS_ROUTE} className="c-text-14 footer__link">
              Discount Spanish lessons
            </Link>
            <Link to={CURSO_FECHAS_ROUTE} className="c-text-14 footer__link">
              Fechas y tarifas
            </Link>
            <Link to={DESCUENTOS_ROUTE} className="c-text-14 footer__link">
              Descuentos
            </Link>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6 col-12">
            <h3 className="footer__subtitle">Centro de asistencia</h3>
            <Link to={ALOJAMIENTO_ROUTE} className="c-text-14 footer__link">
              Alojamiento
            </Link>
            <Link to="/trabaja-con-nosotros" className="c-text-14 footer__link">
              Trabaja con nosotros
            </Link>
            <Link to={MEDIATECA_RECURSOS_ROUTE} className="c-text-14 footer__link">
              Recursos y trabajo
            </Link>
            <Link to={REQUSITO_DE_ACCESO_ROUTE} className="c-text-14 footer__link">
              Requisitos de acceso
            </Link>
            <Link to={PREGUNTAS_FRECUENTES_ROUTE} className="c-text-14 footer__link">
              Preguntas Frecuentes
            </Link>
            <Link to="/politica-de-privacidad" className="c-text-14 footer__link">
              Política de privacidad
            </Link>
            <Link to={CONTACTAR_ROUTE} className="c-text-14 footer__link">
              Contacto
            </Link>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6 col-12 order-sm-first">
            <Link to="/" className="footer__logo">
              <img src={imgFooterLogo} alt="TEFL iberia" />
            </Link>
            <p className="info__description">Cursos para profesores de español</p>
            <a href="https://www.google.com.ua/maps/search/ELE+Barcelona+-+Calle+Valencia+275,+3er+piso+08009+Barcelona,+Espa%C3%B1a/@41.3947257,2.1629225,17z/data=!3m1!4b1?hl=ru&entry=ttu" target="_blank" className="footer__info">
              <img src={imgAdress} alt="imgAdress" />
              <span className="c-text-16 info__text">
                ELE Barcelona - Calle Valencia 275, 3er piso 08009 Barcelona, España
              </span>
            </a>
            <a href="tel:+34934875116" className="footer__info">
              <img src={imgPhone} alt="imgPhone" />
              <span className="c-text-16 info__text">(+34) 934 875 116</span>
            </a>
            <a href="tel:+34634713933" className="footer__info">
              <img src={imgWha} alt="Whatsapp" />
              <span className="c-text-16 info__text">(+34) 634 713 933</span>
            </a>
            <a href="mailto:formacion@elebarcelona.com" className="footer__info">
              <img src={imgMail} alt="imgMail" />
              <span className="c-text-16 info__text">formacion@elebarcelona.com</span>
            </a>
          </div>
        </div>
      </div>
      <div className="c-text-14 footer__bottom text-center">
        <div className="container container--bot">
          <div className="row align-items-center justify-content-between">
            <span>© {new Date().getFullYear()} All rights reserved. </span>
            <p>
              Partnered with{' '}
              <a href="https://owledge.io/" target="_blank" rel="noopener noreferrer">
                Owledge
              </a>
            </p>

            <div className="footer__socials">
              <a
                target="_blank"
                href="https://www.facebook.com/ELE-Barcelona-598738296898402/"
                className="socials__item"
              >
                <ImgFacebook />
              </a>
              <a target="_blank" href="https://twitter.com/ELE_Barcelona" className="socials__item">
                <ImgTwitter />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/elebarcelona/"
                className="socials__item"
              >
                <ImgInstagram />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCKElnkvzVc7im6NRo6YUx-g"
                className="socials__item"
              >
                <ImgYouTube />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
