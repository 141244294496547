import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import useResizeScreen from '../hooks/useResizeScreen';

import {
  ALOJAMIENTO_ROUTE,
  CURSO_FELE_INTENSIVO_ROUTE,
  CURSO_ELE_PLUS_ROUTE,
  DISCOUNT_SPANISH_LESSONS_ROUTE,
  CURSO_FECHAS_ROUTE,
  ORIENTACION_PROFESIONAL_ROUTE,
  ACREDITACIONES_Y_ASOCIACIONES_ROUTE,
  CONOCE_EL_EQUIPO_ROUTE,
  GRADUADOS_DE_ELE_BARCELONA_ROUTE,
  PREGUNTAS_FRECUENTES_ROUTE,
  BLOG_ROUTE,
  MEDIATECA_RECURSOS_ROUTE,
  CONTACTAR_ROUTE,
  INSCRIPCION_ROUTE,
  DESCUENTOS_ROUTE,
  DESCARDA_EL_FOLLETO_ROUTE,
  REQUSITO_DE_ACCESO_ROUTE,
} from '../constants/routes';

import imageLogo from '../assets/images/svg/ELE-Barcelona-logo.svg';
import iconMenu from '../assets/images/menu.svg';
import iconClose from '../assets/images/close.svg';
import IconArrowDown from '../assets/images/svg/arrow-down-header.inline.svg';

const Header = () => {
  const [isOpenMobileMenu, setStateMobileMenu] = useState(false);
  const [openAccordeon, setOpenAccordeon] = useState(0);
  const { isTablet } = useResizeScreen();

  const handleStateMobileMenu = () => {
    setStateMobileMenu(!isOpenMobileMenu);

    const body = document.querySelector('body');
    if (isOpenMobileMenu) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  const handleOpenAccordeon = (event) => {
    const triggerId = event.target.closest('.dropdown__trigger').dataset.trigger;
    if (openAccordeon === Number.parseInt(triggerId)) {
      setOpenAccordeon(0);
    } else {
      setOpenAccordeon(Number.parseInt(triggerId));
    }
  };

  useEffect(() => {
    if (!isOpenMobileMenu) {
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
    }
  }, [isOpenMobileMenu]);

  return (
    <header className={`header ${isOpenMobileMenu ? 'header--mobile' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__wrapper">
              <div className="header__logo">
                <Link to="/">
                  <img src={imageLogo} alt="logo" />
                </Link>
              </div>

              {!isTablet && (
                <div className="header__primary-nav">
                  <nav className="primary-nav">
                    <HeaderMenu />
                  </nav>
                </div>
              )}

              <div className="header__secondary-nav">
                <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--red">
                  Inscríbete ahora
                </Link>
              </div>
              <div className="header__mobile-btn" onClick={handleStateMobileMenu}>
                <img src={isOpenMobileMenu ? iconClose : iconMenu} alt="icon" />
              </div>
            </div>

            {isTablet && (
              <div className="header__mobile">
                <div className="header__mobile__primary-nav">
                  <nav className="primary-nav">
                    <HeaderMenuMobile
                      openAccordeon={openAccordeon}
                      handleOpenAccordeon={handleOpenAccordeon}
                    />
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

const HeaderMenu = () => (
  <ul className="primary-nav__list">
    <li className="primary-nav__item primary-nav__item--hover--lvl-1">
      <div>
        <span className="primary-nav__link">Cursos</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      <div className="primary-nav__dropdown primary-nav__dropdown--lvl-1">
        <ul>
          <li>
            <Link to={CURSO_FELE_INTENSIVO_ROUTE}>Curso FELE Intensivo</Link>
          </li>
          <li>
            <Link to={CURSO_ELE_PLUS_ROUTE}>Curso ELE+</Link>
          </li>
          <li>
            <Link to={DISCOUNT_SPANISH_LESSONS_ROUTE}>Discount Spanish lessons</Link>
          </li>
        </ul>
      </div>
    </li>
    <li className="primary-nav__item primary-nav__item--hover--lvl-1">
      <div>
        <span className="primary-nav__link">Fechas y precios</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      <div className="primary-nav__dropdown primary-nav__dropdown--lvl-1">
        <ul>
          <li>
            <Link to={CURSO_FECHAS_ROUTE}>Fechas y precios</Link>
          </li>
          <li>
            <Link to={DESCUENTOS_ROUTE}>Descuentos</Link>
          </li>
        </ul>
      </div>
    </li>
    <li className="primary-nav__item primary-nav__item--hover--lvl-1">
      <div>
        <span className="primary-nav__link">Trabajo ELE</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      <div className="primary-nav__dropdown primary-nav__dropdown--lvl-1">
        <ul>
          <li>
            <Link to={ORIENTACION_PROFESIONAL_ROUTE}>Orientacion Profesional</Link>
          </li>
          <li>
            <Link to={MEDIATECA_RECURSOS_ROUTE}>Recursos ELE</Link>
          </li>
          <li>
            <Link to="/trabaja-con-nosotros/">Trabaja con nosotros</Link>
          </li>
        </ul>
      </div>
    </li>
    <li className="primary-nav__item primary-nav__item--hover--lvl-1">
      <div>
        <span className="primary-nav__link">Sobre nosotros</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      <div className="primary-nav__dropdown primary-nav__dropdown--lvl-1">
        <ul>
          <li>
            <Link to="/la-historia-de-ele-barcelona/">La historia de ELE Barcelona</Link>
          </li>
          <li>
            <Link to="/que-significa-ele/">¿Qué significa ELE?</Link>
          </li>
          <li>
            <Link to={CONOCE_EL_EQUIPO_ROUTE}>Conoce al equipo</Link>
          </li>
          <li>
            <Link to={ACREDITACIONES_Y_ASOCIACIONES_ROUTE}>Acreditaciones y asociaciones</Link>
          </li>
          <li>
            <Link to={GRADUADOS_DE_ELE_BARCELONA_ROUTE}>Graduados de ELE Barcelona</Link>
          </li>
        </ul>
      </div>
    </li>
    {/* <li className="primary-nav__item primary-nav__item--hover--lvl-1">
      <div>
        <span className="primary-nav__link">Preguntas frecuentes</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      <div className="primary-nav__dropdown primary-nav__dropdown--lvl-1">
        <ul>
          <li>
            <Link to={PREGUNTAS_FRECUENTES_ROUTE}>Todos las preguntas frecuentes</Link>
          </li>
          <li>
            <Link to={REQUSITO_DE_ACCESO_ROUTE}>Requisitos de acceso</Link>
          </li>
          <li>
            <Link to="/covid-19">Covid 19</Link>
          </li>
          <li>
            <Link to={ALOJAMIENTO_ROUTE}>Alojamiento</Link>
          </li>
        </ul>
      </div>
    </li> */}
    <li className="primary-nav__item">
      <Link className="primary-nav__link" to={BLOG_ROUTE}>
        Blog
      </Link>
    </li>
  </ul>
);

const HeaderMenuMobile = ({ openAccordeon, handleOpenAccordeon }) => (
  <ul className="primary-nav__list">
    <li className={`dropdown `}>
      <div
        onClick={handleOpenAccordeon}
        data-trigger="1"
        className={`dropdown__trigger ${openAccordeon === 1 ? 'active' : ''}`}
      >
        <span>Cursos</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      {openAccordeon === 1 ? (
        <div className="dropdown__inner">
          <ul>
            <li>
              <Link to={CURSO_FELE_INTENSIVO_ROUTE}>Curso FELE Intensivo</Link>
            </li>
            <li>
              <Link to={CURSO_ELE_PLUS_ROUTE}>Curso ELE+</Link>
            </li>
            <li>
              <Link to={DISCOUNT_SPANISH_LESSONS_ROUTE}>Discount Spanish lessons</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </li>
    <li className="dropdown">
      <div
        onClick={handleOpenAccordeon}
        data-trigger="2"
        className={`dropdown__trigger ${openAccordeon === 2 ? 'active' : ''}`}
      >
        <span className="primary-nav__link">Fechas y precios</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      {openAccordeon === 2 ? (
        <div className="dropdown__inner">
          <ul>
            <li>
              <Link to={CURSO_FECHAS_ROUTE}>Fechas y precios</Link>
            </li>
            <li>
              <Link to={DESCUENTOS_ROUTE}>Descuentos</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </li>
    <li className="dropdown">
      <div
        onClick={handleOpenAccordeon}
        data-trigger="3"
        className={`dropdown__trigger ${openAccordeon === 3 ? 'active' : ''}`}
      >
        <span className="primary-nav__link">Trabajo ELE</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      {openAccordeon === 3 ? (
        <div className="dropdown__inner">
          <ul>
            <li>
              <Link to={ORIENTACION_PROFESIONAL_ROUTE}>Orientacion Profesional</Link>
            </li>
            <li>
              <Link to={MEDIATECA_RECURSOS_ROUTE}>Recursos ELE</Link>
            </li>
            <li>
              <Link to="/trabaja-con-nosotros/">Trabaja con nosotros</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </li>
    <li className="dropdown">
      <div
        onClick={handleOpenAccordeon}
        data-trigger="4"
        className={`dropdown__trigger ${openAccordeon === 4 ? 'active' : ''}`}
      >
        <span className="primary-nav__link">Sobre nosotros</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      {openAccordeon === 4 ? (
        <div className="dropdown__inner">
          <ul>
            <li>
              <Link to="/la-historia-de-ele-barcelona/">La historia de ELE Barcelona</Link>
            </li>
            <li>
              <Link to="/que-significa-ele/">¿Qué significa ELE?</Link>
            </li>
            <li>
              <Link to={CONOCE_EL_EQUIPO_ROUTE}>Conoce al equipo</Link>
            </li>
            <li>
              <Link to={ACREDITACIONES_Y_ASOCIACIONES_ROUTE}>Acreditaciones y asociaciones</Link>
            </li>
            <li>
              <Link to={GRADUADOS_DE_ELE_BARCELONA_ROUTE}>Graduados de ELE Barcelona</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </li>
    <li className="dropdown">
      <div
        onClick={handleOpenAccordeon}
        data-trigger="5"
        className={`dropdown__trigger ${openAccordeon === 5 ? 'active' : ''}`}
      >
        <span className="primary-nav__link">Preguntas frecuentes</span>
        <i>
          <IconArrowDown />
        </i>
      </div>
      {openAccordeon === 5 ? (
        <div className="dropdown__inner">
          <ul>
            <li>
              <Link to={PREGUNTAS_FRECUENTES_ROUTE}>Todos las preguntas frecuentes</Link>
            </li>
            <li>
              <Link to={REQUSITO_DE_ACCESO_ROUTE}>Requisitos de acceso</Link>
            </li>
            <li>
              <Link to="/covid-19">Covid 19</Link>
            </li>
            <li>
              <Link to={ALOJAMIENTO_ROUTE}>Alojamiento</Link>
            </li>
          </ul>
        </div>
      ) : null}
    </li>
    <li className="dropdown">
      <Link className="dropdown__trigger" to={BLOG_ROUTE}>
        Blog
      </Link>
    </li>
    <li className="dropdown">
      <Link className="dropdown__trigger" to={CONTACTAR_ROUTE}>
        Contacto
      </Link>
    </li>
  </ul>
);

export default Header;
