import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../constants/site-config';

const SEO = ({ title, description, metaImage, url }) => {
  const seoTitle = title || config.siteTitle;
  const seoDescription = description || config.siteDescription;
  const seoURL = url || config.siteUrl;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Website',
      url: config.siteUrl,
      name: seoTitle,
      alternateName: config.siteTitleShort,
      description: seoDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: 'es',
      }}
    >
      <title>{seoTitle}</title>
      <meta name="title" content={seoTitle} />
      <meta name="description" content={seoDescription} />

      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content={'website'} />
      {/* <meta property="og:url" content={seoURL} /> */}
      <meta property="og:locale" content="es" />
      <meta property="og:site_name" content={config.siteTitleShort} />
      {metaImage && <meta property="og:image" content={metaImage.replace('https', 'http')} />}
      {metaImage && <meta property="og:image:secure_url" content={metaImage} />}
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta name="og:email" content={config.userEmail} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:site" content={config.userTwitter || config.siteUrl} />
      {/* <meta name="twitter:image" content={seoImage} /> */}
      <meta name="twitter:creator" content={config.userTwitter || config.siteUrl} />
      <meta name="twitter:domain" content={config.siteUrl} />
      {/* <meta name="twitter:url" content={seoURL} /> */}

      {/* Google Fonts */}
      {/* <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="anonymous" /> */}

      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-55402851-1"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-55402851-1');`}
      </script>

      <meta name="facebook-domain-verification" content="gc2vt8qupg5ljjea5tuw0wamkk0z88" />

      {/* <!-- Meta Pixel Code --> */}
      <script>
        {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1381765469012059');
  fbq('track', 'PageView');`}
      </script>
      <noscript>
        {`<img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=1381765469012059&ev=PageView&noscript=1"
        />`}
      </noscript>
      {/* <!-- End Meta Pixel Code --> */}
    </Helmet>
  );
};

export default SEO;
