import { useState, useEffect } from 'react';

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 768 - 0.02 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 992 - 0.02 : 0);
const IS_LAPTOP = () => (typeof window !== 'undefined' ? window.innerWidth <= 1280 - 0.02 : 0);

const useResizeScreen = () => {
  const [isMobile, setMobile] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [isLaptop, setLaptop] = useState(false);

  const handleResize = () => {
    setMobile(IS_MOBILE());
    setTablet(IS_TABLET());
    setLaptop(IS_LAPTOP());
  };

  useEffect(() => {
    setMobile(IS_MOBILE());
    setTablet(IS_TABLET());
    setLaptop(IS_LAPTOP());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return { isMobile, isTablet, isLaptop };
};

export default useResizeScreen;
