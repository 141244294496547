import React from 'react';

import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';

// Styles
import '../assets/styles/main.scss';

const Layout = ({ children, title, description, metaImage }) => {
  return (
    <>
      <SEO title={title} description={description} metaImage={metaImage} />

      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
